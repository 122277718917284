html,
body,
#__next,
#__next > div {
  width: 100%;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  @apply bg-gray-100;
  border-radius: 999px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-400;
  border-radius: 999px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}

.vms-root {
  @apply flex h-full flex-col lg:flex-row;
}

.vms-page-title {
  @apply text-3xl font-semibold;
}

.vms-page-subtitle {
  @apply text-gray-700;
}

.vms-view {
  @apply my-6 rounded-md bg-white p-5 shadow-md;
}

.vms-container {
  @apply px-4 sm:px-8;
}

.vms-view-title {
  @apply text-lg font-semibold;
}

.vms-view-title-xl {
  @apply text-2xl font-semibold;
}

.vms-view-subtitle {
  @apply text-gray-600;
}

.vms-button-base {
  @apply inline-flex items-center justify-center space-x-1 rounded-md py-2 px-4 text-sm font-semibold;
}

.vms-button-base:disabled {
  @apply cursor-not-allowed opacity-50;
}

.form-field-base {
  @apply relative rounded-md border border-solid border-gray-300 bg-gray-50 px-2 py-1 text-sm outline-none focus:outline-none;
}

.form-field-input-base {
  @apply w-full focus:border-gray-400 focus:ring-2 focus:ring-gray-300;
}

.form-field-input-base:disabled {
  @apply cursor-not-allowed opacity-50;
}

.form-field-popup {
  margin-top: 38px;
  @apply border border-solid border-gray-300 bg-white text-sm outline-none focus:outline-none;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
